import request from '@/utils/request'
let resquest = "/api/"
//反馈列表
export function opinionList(data) {
  return request({
    url: `${resquest}opinion/list`,
    method: 'post',
    data
  })
}
//采纳意见
export function opinionAccept(params) {
  return request({
    url: `${resquest}opinion/accept`,
    method: 'get',
    params
  })
}

//被举报列表
export function myNgtList(data) {
  return request({
    url: `${resquest}my_ngt/list`,
    method: 'post',
    data
  })
}

//反馈回复
export function opinionReply(data) {
  return request({
    url: `${resquest}opinion/reply`,
    method: 'post',
    data
  })
}