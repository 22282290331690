import request from '@/utils/request'
let resquest = "/api/"

export function orderGiftSend(params) {
  return request({
    url: `${resquest}order/giftSend`,
    method: 'get',
    params
  })
}

//订单列表
export function orderList(params) {
  return request({
    url: `${resquest}orders/list`,
    method: 'get',
    params
  })
}
