<template>
  <div class="dashboard-container">
    <el-row :gutter="24">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <div class="title">财务报表</div>
        <!-- <div class="bt">
          
        </div> -->
        <div class="bt">
          <div>
            <!-- <Strong>统计类型：</Strong>
            <el-select
              v-model="form.statisticType"
              filterable
              placeholder="请选择"
              @change="selectTypeClick"
              style="margin-right: 10px; width: 120px"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <Strong>维度：</Strong>
            <el-select
              v-model="form.dimType"
              filterable
              placeholder="请选择"
              @change="selectClick"
              style="margin-right: 10px; width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <Strong>年：</Strong>
            <el-select
              v-model="form.year"
              filterable
              placeholder="请选择"
              @change="selectTwoClick"
              style="margin-right: 10px; width: 100px"
            >
              <el-option
                v-for="item in optionsTwo"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <Strong v-if="form.dimType == 1">月：</Strong>
            <Strong v-if="form.dimType == 2">周：</Strong>
            <el-select
              v-if="form.dimType == 1"
              v-model="form.month"
              filterable
              placeholder="请选择"
              @change="selectThreeClick"
              style="margin-right: 10px; width: 100px"
              clearable
            >
              <el-option
                v-for="item in optionsThree"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-if="form.dimType == 2"
              v-model="form.week"
              filterable
              placeholder="请选择"
              @change="selectFourClick"
              style="margin-right: 10px; width: 100px"
              clearable
            >
              <el-option
                v-for="item in optionsFour"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select> -->
            <!-- <Strong>统计类型：</Strong>
            <el-select
              v-model="statisticType"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 120px"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <Strong>业务主体：</Strong>
            <el-select
              v-model="psSysList"
              clearable
              filterable
              multiple
              placeholder="请选择"
              style="margin-right: 10px; width: 240px"
            >
              <el-option
                v-for="(item, index) in psSysListOne"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <Strong>预计开航日：</Strong>
            <el-date-picker
              v-model="beginTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              :default-value="timeDefaultShowOne"
              unlink-panels
              @change="pickerClick"
              @focus="TimedataBar"
              style="width: 350px"
            ></el-date-picker>
            <br />
            <span style="padding-left: 403px; font-size: 13px">
              <i class="el-icon-warning"></i>
              默认展示前30天数据
            </span>
          </div>
        </div>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <!-- <table-one :dataTime="dataTime" :statisticType="statisticType" ref="tab1"></table-one> -->
        <table-one :dataTime="dataTime" :psSysList="psSysList" ref="tab1"></table-one>
      </el-col>
      <!-- <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <table-two :form="form" ref="tab2"></table-two>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <table-three :form="form" ref="tab3"></table-three>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <table-four :form="form" ref="tab4"></table-four>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <table-five :form="form" ref="tab5"></table-five>
      </el-col> -->
    </el-row>
  </div>
</template>
<script>
import TableOne from './components/TableOne.vue'
// import TableTwo from './components/TableTwo.vue'
// import TableThree from './components/TableThree.vue'
// import TableFour from './components/TableFour.vue'
// import TableFive from './components/TableFive.vue'
// import {
//   getBaseYear,
//   getBaseMonthByYear,
//   getWeekByYear,
// } from '@/api/businessStatement'
export default {
  components: {
    TableOne,
    // TableTwo,
    // TableThree,
    // TableFour,
    // TableFive,
  },
  
  data() {
    let now = new Date() // 当前日期
      // let nowYear = now.getFullYear() //当前年
      let nowYear = 2021 //22年及以后
      let firstDay = new Date(nowYear, 0).getTime() // 本年开始时间
    return {
      // form: {
      // statisticType: '1',
      // dimType: '2',
      // year: '',
      // week: '',
      // month: '',
      // },
      psSysList: [],
      timeDefaultShowOne: '',
      // options: [
      //   {
      //     id: '1',
      //     name: '月维度',
      //   },
      //   {
      //     id: '2',
      //     name: '周维度',
      //   },
      // ],
      // optionsType: [
      //   {
      //     id: '2',
      //     name: '录单日',
      //   },
      //   {
      //     id: '1',
      //     name: '预计开航日',
      //   },
      // ],
      dataTime: {
        beginDate: '',
        endDate: '',
      },
      psSysListOne: [
        {
          id: 'wm',
          name: '万马',
        },
        {
          id: 'sl',
          name: '三力',
        },
        {
          id: 'htj',
          name: '恒泰进',
        },
      ],
      optionsType: [
        {
          id: '1',
          name: '预计开航日',
        },
        {
          id: '3',
          name: '实际开航日',
        },
      ],
      optionsTwo: [],
      optionsThree: [],
      optionsFour: [],
      beginTime: [],
      pickerMinDate: null,
      pickerMaxDate: null,
      time: '',
      timeData: '',
      pickerOptions: {
        
        //点击时间回调 点击时，选择的是开始时间，也就是minDate   //限制一年
        // onPick: ({ maxDate, minDate }) => {
        //   if (minDate) {
        //     let currentYear = minDate.getFullYear()
        //     this.pickerMinDate = new Date(minDate).setFullYear(currentYear - 1)
        //     this.pickerMaxDate = new Date(minDate).setFullYear(currentYear + 1)
        //   }
        // },
        //限制两个月
        // onPick: ({ maxDate, minDate }) => {
        //   if (minDate) {
        //     let currentMonth = minDate.getMonth()
        //     this.pickerMinDate = new Date(minDate).setMonth(currentMonth - 2)
        //     this.pickerMaxDate = new Date(minDate).setMonth(currentMonth + 2)
        //   }
        // },
        //禁用时间 打开选择器就调用  //限制一年
        // disabledDate: (time) => {
        //   console.log(time);
        //   if (this.pickerMinDate != null) {
        //     //点击月份后只允许选前后1年的时间
        //     //return true是禁用
        //     return (
        //       time.getTime() < this.pickerMinDate ||
        //       this.pickerMaxDate < time.getTime()
        //     )
        //   }
        // },
        //限制两个月
        // disabledDate: (time) => {
        //   if (this.pickerMinDate != null) {
        //     //点击月份后只允许选前后两个月的时间
        //     //return true是禁用
        //     return (
        //       time.getTime() < this.pickerMinDate ||
        //       this.pickerMaxDate < time.getTime()
        //     )
        //   }
        // },
      },
    }
  },
  mounted() {
    // this.selectTwo()
    this.timeDefaultShowOne = new Date()
    this.timeDefaultShowOne.setMonth(new Date().getMonth() - 1)
    this.queryOne()
  },
  methods: {
    // selectTypeClick() {
    //   this.queryOne()
    // },
    // selectClick() {
    //   this.form.year = ''
    //   this.form.month = ''
    //   this.form.week = ''
    //   this.queryOne()
    // },
    // selectTwoClick() {
    //   this.queryOne()
    //   this.selectThree()
    // },
    // selectThreeClick() {
    //   this.queryOne()
    // },
    // selectFourClick() {
    //   this.queryOne()
    // },
    // async selectTwo() {
    //   const res = await getBaseYear()
    //   this.optionsTwo = res.data
    // },
    // async selectThree() {
    //   let data = {
    //     year: this.form.year,
    //   }
    //   if (this.form.dimType == 1) {
    //     const res = await getBaseMonthByYear(data)
    //     this.optionsThree = res.data
    //   } else {
    //     const res = await getWeekByYear(data)
    //     this.optionsFour = res.data
    //   }
    // },
    async queryOne() {
      //市场T量-票数表
      await this.$refs.tab1.queryList()
      // //部门T量-票数表
      // await this.$refs.tab2.queryList()
      // //船司T量-票数表
      // await this.$refs.tab3.queryList()
      // //订舱代理-船司货量表
      // await this.$refs.tab4.queryList()
      // //订舱代理-航线货量表
      // await this.$refs.tab5.queryList()
    },
    pickerClick(val) {
      // if (val != null) {
      //   var startdate = val[0] //开始日期
      //   var enddate = val[1] //结束日期
      //   if (
      //     parseInt(enddate.substring(0, 4)) -
      //       parseInt(startdate.substring(0, 4)) ==
      //     1
      //   ) {
      //     if (parseInt(startdate.substring(5, 7)) < 2) {
      //       this.beginTime = []
      //       this.$message({
      //         message: '查询日期不能超过两个月',
      //         type: 'warning',
      //       })
      //       return false
      //     } else if (
      //       parseInt(startdate.substring(5, 7)) +
      //         2 -
      //         parseInt(enddate.substring(5, 7)) <=
      //       12
      //     ) {
      //       if (
      //         parseInt(enddate.substring(8, 10)) -
      //           parseInt(startdate.substring(8, 10)) >=
      //         0
      //       ) {
      //         this.beginTime = []
      //         this.$message({
      //           message: '查询日期不能超过两个月',
      //           type: 'warning',
      //         })
      //         return false
      //       }
      //     }
      //   } else if (
      //     parseInt(enddate.substring(0, 4)) -
      //       parseInt(startdate.substring(0, 4)) ==
      //     0
      //   ) {
      //     if (
      //       parseInt(enddate.substring(5, 7)) -
      //         parseInt(startdate.substring(5, 7)) >
      //       2
      //     ) {
      //       this.beginTime = []
      //       this.$message({
      //         message: '查询日期不能超过两个月',
      //         type: 'warning',
      //       })
      //       return false
      //     } else if (
      //       parseInt(enddate.substring(5, 7)) -
      //         parseInt(startdate.substring(5, 7)) ==
      //       2
      //     ) {
      //       if (
      //         parseInt(enddate.substring(8, 10)) -
      //           parseInt(startdate.substring(8, 10)) >=
      //         0
      //       ) {
      //         this.beginTime = []
      //         this.$message({
      //           message: '查询日期不能超过两个月',
      //           type: 'warning',
      //         })
      //         return false
      //       }
      //     }
      //   } else {
      //     this.beginTime = []
      //     this.$message({
      //       message: '周维度查询日期不能超过半年',
      //       type: 'warning',
      //     })
      //     return false
      //   }
      // }
      this.dataTime.beginDate = this.beginTime ? this.beginTime[0] : ''
      this.dataTime.endDate = this.beginTime ? this.beginTime[1] : ''
      // this.queryOne()
    },
    TimedataBar() {
      let now = new Date() // 当前日期
      // let nowYear = now.getFullYear() //当前年
      let nowYear = 2022 //22年及以后
      let firstDay = new Date(nowYear, 0).getTime() // 本年开始时间
      // let LastDay = new Date(nowYear + 1, -1).getTime() // 本年结束时间

      this.pickerOptions = {
        //   disabledDate: (time) => {
        //     const day = 365 * 24 * 3600 * 1000 // 31536000000
        //     // 返回小于当前日期并近一年内的日期
        //     return (
        //       time.getTime() > Date.now() ||
        //       time.getTime() < Date.now() - 8.64e7 - day
        //     )
        //   },
        // disabledDate(time) {
        //   let curDate = new Date().getTime()
        //   let one = 365 * 24 * 3600 * 1000
        //   let oneYear = curDate + one
        //   return (
        //     time.getTime() > oneYear || time.getTime() < Date.now() - 8.64e7
        //   )
        // },
        disabledDate: (time) => {
          return (
            time.getTime() < firstDay ||
            // time.getTime() > LastDay ||
            time.getTime() > now - 86400000
          )
          // return time.getTime() > now - 86400000 // 选当前时间之前的时间
        },
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.bt {
  float: right;
  margin: 10px 0px;
}
</style>