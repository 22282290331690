<template>
  <!--      轮播图-->
  <div class="carousel-map">
    <div class="bt">
      <img width="150px" src="@/assets/logo.png" alt="" />
    </div>
    <img src="http://www.myeverocean.com/wp-content/uploads/2022/04/1d7d223ffedcdda26deecb9b750f448-scaled.jpg" class="breath" width="100%"  alt="" />
    <!-- <el-carousel
      :interval="4000"
      @change="changeImg"
      style="width: 100%; margin-top: 1px"
      trigger="click"
      height="43.5rem"
    >
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <el-image
          :class="className"
          style="width: 100%; height: 100%"
          :src="item.contentCover"
          fit="cover"
        ></el-image>
      </el-carousel-item>
    </el-carousel> -->
  </div>
</template>
<script>
export default {
  name: 'home',
  data() {
    return {
      bannerList: [
        // {
        //   contentCover:
        //     'https://www.aaalogisticsgroup.com/wp-content/uploads/2021/05/airport-terminal-building-with-planes-e1607325048503.jpg',
        // },
        // {
        //   contentCover:
        //     'https://mydidadi.com/wp-content/uploads/2018/01/expertise-1.jpg',
        // },
        {
          contentCover:
            'http://www.myeverocean.com/wp-content/uploads/2022/04/1d7d223ffedcdda26deecb9b750f448-scaled.jpg',
        },
      ], //轮播图地址 这块写你们的轮播图数组即可
      className: '', //轮播图名字
    }
  },
  mounted() {
    // this.className = 'lun-img'
    // setTimeout(() => {
    //   this.className = 'lun-img-two'
    // }, 3000)
    // this.className = 'lun-img-two'
    // setTimeout(() => {
    //   this.className = 'lun-img'
    // }, 3000)
  },
  methods: {
    //轮播图切换
    // changeImg(e) {
    //   // console.log(e, "当前下标");
    //   this.className = 'lun-img'
    //   setTimeout(() => {
    //     this.className = 'lun-img-two'
    //   }, 300)
    // },
  },
}
</script>
<style lang="scss" scoped>
//轮播图
.carousel-map {
  width: 100%;
  height: 43.5rem;
  overflow: hidden;
  position: relative;
  .bt {
    position: absolute;
    z-index: 999;
    left: 20px;
    top: 20px;
    color: #fff;
  }
  .lun-img {
    transform: scale(1.5);
    animation-iteration-count: infinite;
  }
  .lun-img-two {
    transition: all 3s;
    transform: scale(1);
    animation-iteration-count: infinite;
  }
  .el-carousel__item.is-animating {
    transition: all 0.6s;
    animation-iteration-count: infinite;
  }
}
.breath {
	animation: breathe 5s infinite;
	-webkit-animation: breathe 5s infinite;/* Safari and Chrome */
}

@keyframes breathe {
	0%,100% {
		transform: scale(1.2, 1.2);
	}

	50% {
		transform: scale(1.0, 1.0);
	}
}

@-webkit-keyframes breathe/* Safari and Chrome */{
	0%,100%{
		-webkit-transform: scale(1.2, 1.2);
	}

	50% {
		-webkit-transform: scale(1.0, 1.0);
	}
}
</style>