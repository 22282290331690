var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("span", [_vm._v(_vm._s(_vm.title))])]
          },
          proxy: true
        }
      ])
    },
    [
      _c("vab-chart", {
        attrs: {
          "init-options": _vm.initOptions,
          option: _vm.option,
          theme: "vab-echarts-theme"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }