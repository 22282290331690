import request from '@/utils/request'
let resquest = "/api/"

//获取充值瓶盖价格列表
export function capPriceList(params) {
  return request({
    url: `${resquest}shop/cap/price/list`,
    method: 'get',
    params
  })
}

//删除瓶盖商品
export function capPriceDelete(params) {
  return request({
    url: `${resquest}shop/cap/price/delete`,
    method: 'get',
    params
  })
}

//添加瓶盖商品
export function capPriceAdd(data) {
  return request({
    url: `${resquest}shop/cap/price/add`,
    method: 'post',
    data
  })
}

//更新瓶盖商品
export function capPriceUpdate(data) {
  return request({
    url: `${resquest}shop/cap/price/update`,
    method: 'post',
    data
  })
}

//获取充值瓶盖价格列表
export function shopCapPriceList(params) {
  return request({
    url: `${resquest}shop/cap/price/all/list`,
    method: 'get',
    params
  })
}

//获取充值瓶盖价格列表
export function baseVipAllList(params) {
  return request({
    url: `${resquest}base/vip/card/all/list`,
    method: 'get',
    params
  })
}

//获取充值瓶盖价格列表
export function userPresentCommodity(data) {
  return request({
    url: `${resquest}user/present/commodity`,
    method: 'post',
    data
  })
}