import request from '@/utils/request'
let resquest = "/api/"

export function giftList(params) {
  return request({
    url: `${resquest}shop/gift/list`,
    method: 'get',
    params
  })
}

export function giftAdd(data) {
  return request({
    url: `${resquest}shop/gift/add`,
    method: 'post',
    data
  })
}

export function giftDelete(params) {
  return request({
    url: `${resquest}shop/gift/delete`,
    method: 'get',
    params
  })
}

export function giftGet(params) {
  return request({
    url: `${resquest}shop/gift/get`,
    method: 'get',
    params
  })
}

export function giftUpdate(data) {
  return request({
    url: `${resquest}shop/gift/update`,
    method: 'post',
    data
  })
}

export function fileUploadFile(data) {
  return request({
    url: `${resquest}file/uploadFile/img`,
    method: 'post',
    data
  })
}
