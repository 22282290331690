import request from '@/utils/request'
let resquest = "/api/"

//分页查询常用语列表
export function baseCommonWords(params) {
  return request({
    url: `${resquest}base/common/words/list`,
    method: 'get',
    params
  })
}

//删除常用语信息 
export function baseCommonWordsDelete(params) {
  return request({
    url: `${resquest}base/common/words/delete`,
    method: 'get',
    params
  })
}

//新增常用语信息 
export function baseCommonWordsNew(data) {
  return request({
    url: `${resquest}base/common/words/new`,
    method: 'post',
    data
  })
}

//修改常用语信息 
export function baseCommonWordsUpdate(data) {
  return request({
    url: `${resquest}base/common/words/update`,
    method: 'post',
    data
  })
}

//分页查询标签列表
export function baseLabelList(params) {
  return request({
    url: `${resquest}base/label/list`,
    method: 'get',
    params
  })
}

//删除标签 
export function baseLabelDelete(params) {
  return request({
    url: `${resquest}base/label/delete`,
    method: 'get',
    params
  })
}

//新增标签 
export function baseLabelNew(data) {
  return request({
    url: `${resquest}base/label/new`,
    method: 'post',
    data
  })
}

//修改标签 
export function baseLabelUpdate(data) {
  return request({
    url: `${resquest}base/label/update`,
    method: 'post',
    data
  })
}

//分页查询会员卡列表
export function baseVipCardList(data) {
  return request({
    url: `${resquest}base/vip/card/list`,
    method: 'post',
    data
  })
}

//删除会员卡
export function baseVipCardDelete(params) {
  return request({
    url: `${resquest}base/vip/card/delete`,
    method: 'get',
    params
  })
}

//新增会员卡
export function baseVipCardAdd(data) {
  return request({
    url: `${resquest}base/vip/card/add`,
    method: 'post',
    data
  })
}

//修改会员卡 
export function baseVipCardUpdate(data) {
  return request({
    url: `${resquest}base/vip/card/update`,
    method: 'post',
    data
  })
}

//分页查询学校列表
export function universityList(params) {
  return request({
    url: `${resquest}university/list`,
    method: 'get',
    params
  })
}

//删除学校
export function universityDelete(params) {
  return request({
    url: `${resquest}university/delete`,
    method: 'get',
    params
  })
}

//新增学校
export function universityNew(data) {
  return request({
    url: `${resquest}university/new`,
    method: 'post',
    data
  })
}

//修改学校
export function universityUpdate(data) {
  return request({
    url: `${resquest}university/update`,
    method: 'post',
    data
  })
}

//审核学校
export function universityCheck(data) {
  return request({
    url: `${resquest}university/check`,
    method: 'post',
    data
  })
}

//学历
export function baseEducationList(params) {
  return request({
    url: `${resquest}base/education/list`,
    method: 'get',
    params
  })
}
