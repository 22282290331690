<template>
  <div>
    <div class="bt">
      <div>
        <h3>部门货量-票数表</h3>
      </div>
      <div>
        <el-form ref="queryForm" :inline="true">
          <el-form-item label="部门/组：" prop="dempId">
            <el-select
              v-model="queryForm.dempId"
              @change="selectClickOne"
              clearable
              multiple
              filterable
              collapse-tags
              style="width: 250px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序列名：" prop="sortName">
            <el-select
              v-model="queryForm.sortName"
              @change="selectClickOne"
              filterable
              clearable
              collapse-tags
              style="width: 150px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionsTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序方式：" prop="sortRule">
            <el-select
              v-model="queryForm.sortRule"
              @change="selectClickOne"
              filterable
              clearable
              collapse-tags
              style="width: 150px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionsThree"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div style="float: right">
            <!-- <el-button type="primary" @click="handleQuery">搜索</el-button> -->
            <el-button type="success" @click="exPort" :disabled="list == null">
              导出
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      element-loading-text="拼命加载中"
      :height="height"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        prop="dimContent"
        label="日期"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="部门"
        prop="dempName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="demphead"
        label="主管"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="员工	"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orders"
        label="票数"
      ></el-table-column>
      <el-table-column
        align="center"
        label="环比票数差额"
        prop="chainRatioOrdersDif"
      ></el-table-column>
      <el-table-column
        align="center"
        label="货量"
        prop="teus"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="环比货量差额"
        prop="chainRatioTeusDif"
        show-overflow-tooltip
      ></el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import { getDemp } from '@/api/metrics'
import { getPageDempOrdersTeus } from '@/api/businessStatement'
import axios from 'axios'
export default {
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      height: '400px',
      listLoading: false,
      // list: [],
      list: [],
      selectRows: '',
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        dempId: [],
        sortName: 'teus',
        sortRule: '2',
      },
      options: [],
      optionsTwo: [
        {
          id: 'orders',
          name: '票数',
        },
        {
          id: 'teus',
          name: '货量',
        },
      ],
      optionsThree: [
        {
          id: '1',
          name: '升序',
        },
        {
          id: '2',
          name: '降序',
        },
      ],
      total: 0,
      layout: 'total, sizes, prev, pager, next, jumper',
    }
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        this.queryForm.pageNum = 1
      },
      deep: true,
    },
  },
  mounted() {
    this.selectTwo()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.queryList()
    },
    async selectTwo() {
      let data = {
        companyId: this.company.companyId,
        dempTypeId: 3,
      }
      let res = await getDemp(data)
      this.options = res.data
    },
    selectClickOne() {
      this.queryForm.pageNum = 1
      this.queryList()
    },
    // handleQuery() {},
    exPort() {
      let data = {
        companyId: this.company.companyId,
        dimType: this.form.dimType,
        year: this.form.year,
        month: this.form.month,
        week: this.form.week,
        dempId: this.queryForm.dempId,
        sortName: this.queryForm.sortName,
        sortRule: this.queryForm.sortRule,
        statisticType: this.form.statisticType,
      }
      axios({
        url: `/api/api/report/business/exportDempOrdersTeus`,
        data,
        method: 'post',
        responseType: 'blob',
        headers: {
          token: localStorage.getItem('token'),
        },
      })
        .then((response) => {
          const content = response.data
          const blob = new Blob([content])
          // const fileName = decodeURIComponent(
          //   response.headers['content-disposition'].split('=')[1] //文件名
          // )
          const fileName = response.headers['content-disposition'].split('=')[1] //文件名
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async queryList() {
      this.listLoading = true
      let data = {
        pageNum: this.queryForm.pageNum,
        pageSize: this.queryForm.pageSize,
        companyId: this.company.companyId,
        dimType: this.form.dimType,
        year: this.form.year,
        month: this.form.month,
        week: this.form.week,
        dempId: this.queryForm.dempId,
        sortName: this.queryForm.sortName,
        sortRule: this.queryForm.sortRule,
        statisticType: this.form.statisticType,
      }
      const res = await getPageDempOrdersTeus(data)
      this.list = res.data.pageList
      this.total = res.data.totalSize
      this.listLoading = false
    },
  },
}
</script>
<style lang="scss" scoped>
</style>