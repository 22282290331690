<template>
  <div class="dashboard-container">
    <el-row :gutter="24">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <div class="bt">
          <div class="bt-left">商务费用看板</div>
          <div>
            <Strong>业务主体：</Strong>
            <el-select
              v-model="psSysList"
              clearable
              filterable
              multiple
              placeholder="请选择"
              @change="selectTypeClick"
              style="margin-right: 5px; width: 240px"
            >
              <el-option
                v-for="(item,index) in psSysListOne"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <Strong>统计类型：</Strong>
            <el-select
              v-model="statisticType"
              filterable
              placeholder="请选择"
              @change="selectTypeClick"
              style="margin-right: 5px; width: 120px"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <Strong>维度：</Strong>
            <el-select
              v-model="dimType"
              filterable
              placeholder="请选择"
              @change="selectClick"
              style="margin-right: 5px; width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <Strong>日期：</Strong>
            <el-date-picker
              v-show="dimType == '2'"
              v-model="beginTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsOne"
              :default-value="timeDefaultShowOne"
              unlink-panels
              @change="pickerClick"
              @focus="TimedataBar"
              style="width: 335px"
            ></el-date-picker>
            <el-date-picker
              v-show="dimType == '1'"
              v-model="beginTime"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              @change="pickerClickTwo"
              :picker-options="pickerOptions"
              :default-value="timeDefaultShowTwo"
              value-format="yyyy-MM-dd"
              unlink-panels
              @focus="TimedataBar"
              style="width: 335px"
            ></el-date-picker>
          </div>
        </div>
      </el-col>
      <!-- <core-one
        ref="son1"
        :dataTime="dataTime"
        :statisticType="statisticType"
        :dimType="dimType"
        :optionsList="optionsList"
        :permissionControl="permissionControl"
      /> -->
      <core-two
        ref="son2"
        :dataTime="dataTime"
        :statisticType="statisticType"
        :dimType="dimType"
        :optionsList="optionsList"
        :permissionControl="permissionControl"
        :psSysList="psSysList"
      />
    </el-row>
  </div>
</template>
<script>
// import { getRevCost, getOrdersTeus, getRecPayAble } from '@/api/metrics'
import CoreOne from './components/CoreOne.vue'
import CoreTwo from './components/CoreTwo.vue'
import {
  getDempHistorytInfoList,
} from '@/api/businessKanban'
export default {
  components: {
    CoreOne,
    CoreTwo,
  },
  data() {
    return {
      permissionControl:1,
      beginTime: [],
      pickerOptionsOne: {},
      pickerMinDate: null,
      pickerMaxDate: null,
      pickerOptions: {
        //点击时间回调
        onPick: ({ maxDate, minDate }) => {
          if (minDate) {
            let currentYear = minDate.getFullYear()
            this.pickerMinDate = new Date(minDate).setFullYear(currentYear - 1)
            this.pickerMaxDate = new Date(minDate).setFullYear(currentYear + 1)
          }
        },
        //禁用时间 打开选择器就调用
        disabledDate: (time) => {
          if (this.pickerMinDate != null) {
            //点击月份后只允许选前后1年的时间
            //return true是禁用
            return (
              time.getTime() < this.pickerMinDate ||
              this.pickerMaxDate < time.getTime()
            )
          }
        },
      },
      dimType: '2',
      statisticType: '1',
      // dataFive: {},
      dataTime: {
        beginDate: '',
        endDate: '',
      },
      timeDefaultShowOne: '',
      timeDefaultShowTwo: '',
      optionsList:[],
      psSysList:[],
      psSysListOne:[
        {
          id: 'wm',
          name: '万马',
        },
        {
          id: 'sl',
          name: '三力',
        },
        {
          id: 'htj',
          name: '恒泰进',
        }
      ],
      options: [
        {
          id: '1',
          name: '月维度',
        },
        {
          id: '2',
          name: '周维度',
        },
      ],
      optionsType: [
        {
          id: '1',
          name: '预计开航日',
        },
        {
          id: '3',
          name: '实际开航日',
        },
      ],
    }
  },
  mounted() {
    // //周
    this.timeDefaultShowOne = new Date()
    this.timeDefaultShowOne.setMonth(new Date().getMonth() - 1)
    // //月
    this.timeDefaultShowTwo = new Date()
    this.timeDefaultShowTwo.setMonth(new Date().getMonth() - 12)
    this.queryOne()
  },
  methods: {
    //部门下拉框
    async selectList(){
      let data = {
        companyId: this.company.companyId,
        dempTypeId: 3,
        dimType: this.dimType,
        beginDate: this.dataTime.beginDate ? this.dataTime.beginDate : '',
        endDate: this.dataTime.endDate ? this.dataTime.endDate : '',
        permissionControl:this.permissionControl,
        psSysList:this.psSysList
      }
      let res = await getDempHistorytInfoList(data)
      this.optionsList = res.data
    },
    selectTypeClick() {
      this.queryOne()
    },
    pickerClickTwo(value) {
      if (value != null) {
        let data = value[1]
        let dateObj = new Date(data)
        let y = dateObj.getFullYear() //获取年份
        let m = (dateObj.getMonth() + 1).toString().padStart(2, '0') //获取月份
        let nextMonth = dateObj.getMonth() + 1 //0-11，下一个月
        //设置当前日期为下个月的1号
        dateObj.setMonth(nextMonth)
        dateObj.setDate(1) //1-31
        let nextMonthFirstDayTime = dateObj.getTime() //下个月一号对应毫秒
        let theMonthLastDayTime = nextMonthFirstDayTime - 24 * 60 * 60 * 1000 //下个月一号减去一天，正好是这个月最后一天
        let theMonthDay = new Date(theMonthLastDayTime).getDate()
        let endDate = y + '-' + m + '-' + theMonthDay
        this.dataTime.beginDate = value ? value[0] : ''
        this.dataTime.endDate = endDate
        // this.beginTime[1] = endDate
        this.queryOne()
      } else {
        this.beginTime = []
        this.dataTime = {
          beginDate: '',
          endDate: '',
        }
        this.queryOne()
      }
    },
    async queryOne() {
      this.selectList()
      // //货量明细
      this.$nextTick(async () => {
        // await this.$refs.son1.handleQuery()
        // await this.$refs.son2.handleQuery()
        await this.$refs.son2.handleQuery()
      })
    },
    pickerClick(date) {
      if (date != null) {
        var startdate = date[0] //开始日期
        var enddate = date[1] //结束日期
        //开始日期和结束日期之间不能大于一个月
        if (
          parseInt(enddate.substring(0, 4)) -
            parseInt(startdate.substring(0, 4)) ==
          1
        ) {
          if (parseInt(startdate.substring(5, 7)) < 7) {
            this.beginTime = []
            this.$message({
              message: '周维度查询日期不能超过半年',
              type: 'warning',
            })
            return false
          } else if (
            parseInt(startdate.substring(5, 7)) +
              6 -
              parseInt(enddate.substring(5, 7)) <=
            12
          ) {
            if (
              parseInt(enddate.substring(8, 10)) -
                parseInt(startdate.substring(8, 10)) >=
              0
            ) {
              this.beginTime = []
              this.$message({
                message: '周维度查询日期不能超过半年',
                type: 'warning',
              })
              return false
            }
          }
        } else if (
          parseInt(enddate.substring(0, 4)) -
            parseInt(startdate.substring(0, 4)) ==
          0
        ) {
          if (
            parseInt(enddate.substring(5, 7)) -
              parseInt(startdate.substring(5, 7)) >
            6
          ) {
            this.beginTime = []
            this.$message({
              message: '周维度查询日期不能超过半年',
              type: 'warning',
            })
            return false
          } else if (
            parseInt(enddate.substring(5, 7)) -
              parseInt(startdate.substring(5, 7)) ==
            6
          ) {
            if (
              parseInt(enddate.substring(8, 10)) -
                parseInt(startdate.substring(8, 10)) >=
              0
            ) {
              this.beginTime = []
              this.$message({
                message: '周维度查询日期不能超过半年',
                type: 'warning',
              })
              return false
            }
          }
        } else {
          this.beginTime = []
          this.$message({
            message: '周维度查询日期不能超过半年',
            type: 'warning',
          })
          return false
        }
      }
      this.dataTime.beginDate = this.beginTime ? this.beginTime[0] : ''
      this.dataTime.endDate = this.beginTime ? this.beginTime[1] : ''
      this.queryOne()
    },
    selectClick(val) {
      this.beginTime = []
      this.dataTime = {
        beginDate: '',
        endDate: '',
      }
      this.queryOne()
    },
    //限制日期
    TimedataBar() {
      let now = new Date() // 当前日期
      // let nowYear = now.getFullYear() //当前年
      let nowYear = 2022 //22年及以后
      let firstDay = new Date(nowYear, 0).getTime() // 本年开始时间
      // let LastDay = new Date(nowYear + 1, -1).getTime() // 本年结束时间
      this.pickerOptionsOne = {
        disabledDate: (time) => {
          return (
            time.getTime() < firstDay ||
            // time.getTime() > LastDay ||
            time.getTime() > now - 86400000
          )
        },
      }
      this.pickerOptions = {
        disabledDate: (time) => {
          return (
            time.getTime() < firstDay ||
            // time.getTime() > LastDay ||
            time.getTime() > now - 86400000
          )
        },
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.dashboard-container {
  width: 100%;
  height: 100%;
  background: #51b4f1 !important;
  .bt {
    background: #608eea;
    height: 60px;
    line-height: 60px;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .bt-left {
    color: #000;
    font-weight: 600;
    font-size: 20px;
  }
}
</style>