<template>
  <el-card shadow="hover">
    <template #header>
      <span>{{ title }}</span>
    </template>
    <vab-chart
      :init-options="initOptions"
      :option="option"
      theme="vab-echarts-theme"
    />
  </el-card>
</template>

<script>
import VabChart from '@/extra/VabChart'

export default {
  name: 'VabChartLine',
  components: {
    VabChart,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      initOptions: {
        renderer: 'svg',
      },

      option: {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 40,
        },
        xAxis: {
          type: 'category',
          data: ['0时', '6时', '12时', '24时', '30时', '36时', '42时', '48时'],
        },
        yAxis: {
          type: 'value',
        },
        series: {
          name: '人数',
          data: [150, 230, 224, 218, 135, 147, 260, 300],
          type: 'line',
        },
      },
    }
  },
}
</script>
