<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <el-card class="trend-card" shadow="hover">
      <template #header>
        <div class="bt1">
          <vab-icon icon="line-chart-fill" />
          商务部人员-订船代理票数
        </div>
        <div class="bt2">
          <el-form ref="queryForm" :inline="true">
            <el-form-item prop="dempId" label="部门：">
              <el-select
                clearable
                filterable
                v-model="queryForm.dempId"
                placeholder="请选择部门"
                @change="selectClickOne"
              >
                <el-option
                  v-for="(item, index) in optionsList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <div id="eachSix" style="width: 100%; height: 500px" ref="myChart"></div>
    </el-card>
  </el-col>
</template>

<script>
import VabChart from '@/extra/VabChart'
import * as echarts from 'echarts'
import {
  getDempPayTommy,
  // getDempHistorytInfoList,
} from '@/api/businessKanban'
import { mapGetters } from 'vuex'
export default {
  name: 'VabChartLine',
  components: {
    VabChart,
  },
  props: {
    dataTime: {
      type: Object,
    },
    dimType: String,
    statisticType: String,
    permissionControl: Number,
    optionsList: Array,
  },
  data() {
    return {
      initOptions: {
        renderer: 'svg',
      },
      option: {},
      queryForm: {
        dempId: '',
      },
      // optionsOne: [],
      statiContent: [],
      dimContent: '',
      dimList: '',
      end: 100,
      start: 0,
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'settings/collapse',
    }),
  },
  watch: {
    dataTime: {
      handler: function (val) {
        let day =
          new Date(val.endDate).getTime() - new Date(val.beginDate).getTime()
        let dayCount = Math.floor(day / 86400000)
        if (dayCount > 62) {
          this.end = 80
          this.start = 100
        } else {
          this.end = 100
          this.start = 0
        }
      },
      deep: true,
    },
    collapse(vlaue) {
      setTimeout(() => {
        var myChart = echarts.init(document.getElementById('eachSix'))
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.option)
        myChart.resize()
      }, 500)
    },
  },
  //清除Echarts数据缓存
  beforeDestroy() {
    var chartDom = document.getElementById('eachSix')
    var myChart = echarts.init(chartDom)
    myChart.clear()
  },
  methods: {
    selectClickOne() {
      this.handleQuery()
    },
    async handleQuery() {
      let data = {
        companyId: this.company.companyId,
        beginDate: this.dataTime.beginDate ? this.dataTime.beginDate : '',
        endDate: this.dataTime.endDate ? this.dataTime.endDate : '',
        dimType: this.dimType,
        dempId: this.queryForm.dempId,
        statisticType: this.statisticType,
        permissionControl: this.permissionControl,
      }
      const res = await getDempPayTommy(data)
      this.statiContent = res.data.statiContent
      this.dimContent = res.data.dimContent
      if (res.data.dimList != null) {
        this.dimList = []
        for (let i = 0; i < res.data.dimList.length; i++) {
          this.dimList.push({
            showSymbol: false,
            name: res.data.dimList[i].name,
            type: 'bar',
            barWidth: '20%',
            stack: '1',
            data: res.data.dimList[i].data,
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          })
        }
      }
      this.optionList()
    },
    optionList() {
      // var dataZong = [0, 0, 0, 0, 0, 0, 0]
      var chartDom = document.getElementById('eachSix')
      var myChart = echarts.init(chartDom)
      var option = {
        color: [
          '#1890FF',
          '#FFA500',
          '#48D1CC',
          '#FF0033',
          '#9370DB',
          '#00BFFF',
          '#FFFF00',
          '#7CFC00',
          '#D2691E',
          '#0000CD',
          '#CC6666',
          '#CC99FF',
          '#990033',
          '#FF69B4',
          '#339900',
          '#FFCCCC',
          '#00FFFF',
          '#996666'
        ],
        legend: {
          // itemHeight: 24,
          top: '-1%',
          height: '50px',
          // padding: [0, 0, 0, 0],
          bottom: 0,
          type: 'scroll', // 设置图例翻页
          data: this.statiContent,
          // itemGap: 20,
          // itemWidth: 20,
        },
        // legend: {
        //   //纵向排列
        //   orient: 'vertical',
        //   //与外部容器之间的距离
        //   left: '5%',
        //   top: '30%',
        //   //每一项之间的间隔
        //   itemGap: 25,
        //   //格式化每一项内容
        //   formatter(name) {
        //     //只接收一个参数，就是类目名称
        //     let value
        //     //使用name去放内容的数组中拿到对应的值
        //     this.statiContent.forEach((item) => {
        //       if (item.name == name) {
        //         value = item.value
        //       }
        //     })
        //     //name, value可以理解为样式标记符，用于在富文本中设置对应的样式
        //     return [`{name|${name}}`, `{value|${value}}`].join('\n')
        //     // \n表示换行
        //   },
        //   //使用富文本去定义样式
        //   textStyle: {
        //     rich: {
        //       name: {
        //         fontSize: 14,
        //         color: '#3e4555',
        //         lineHeight: 20,
        //       },
        //       value: {
        //         fontSize: 20,
        //         color: '#3e4555',
        //         lineHeight: 24,
        //       },
        //     },
        //   },
        // },
        // tooltip: {
        //   trigger: 'axis',
        //   extraCssText: 'z-index:1',
        //   axisPointer: {
        //     type: 'shadow',
        //   },
        // },
        tooltip: {
          trigger: 'axis',
          extraCssText: 'z-index:1',
          enterable: true, //悬停
          extraCssText: 'max-width:60%;max-height:60%; overflow: auto; ', //滚动条
          //改变提示框的位置 不超出屏幕显示
          position: function (point, params, dom, rect, size) {
            //其中point为当前鼠标的位置，
            //size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0 // x坐标位置
            var y = 0 // y坐标位置
            // 当前鼠标位置
            var pointX = point[0]
            var pointY = point[1]
            // 提示框大小
            var boxWidth = size.contentSize[0]
            var boxHeight = size.contentSize[1]
            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5
            } else {
              // 左边放的下
              x = pointX - boxWidth
            }
            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5
            } else {
              // 上边放得下
              y = pointY - boxHeight
            }
            return [x, y]
          },
          // alwaysShowContent: true,
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let newParams = []
            let tooltipString = []
            newParams = [...params]
            newParams.sort((a, b) => {
              return b.value - a.value
            })
            let sum = 0
            tooltipString.push(newParams[0].axisValueLabel + '<br/>')
            newParams.forEach((p) => {
              sum += p.value
              if (p.value != 0) {
                const cont =
                  p.marker + ' ' + p.seriesName + ': ' + p.value + '<br/>'
                tooltipString.push(cont)
              }
            })
            tooltipString.push(`合计 : ${sum}`)
            return tooltipString.join('')
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'slider', //有单独的滑动条，用户在滑动条上进行缩放或漫游。inside是直接可以是在内部拖动显示
            show: true, //是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
            start: this.start, //数据窗口范围的起始百分比0-100
            end: this.end, //数据窗口范围的结束百分比0-100
            xAxisIndex: [0], // 此处表示控制第一个xAxis，设置 dataZoom-slider 组件控制的 x轴 可是已数组[0,2]表示控制第一，三个；xAxisIndex: 2 ，表示控制第二个。yAxisIndex属性同理
            bottom: '0px', //距离底部的距离
            height: 12,
            handleSize: '80%',
            realtime: false,
            handleIcon:
              'path://M512,512m-448,0a448,448,0,1,0,896,0a448,448,0,1,0,-896,0Z',
            handleStyle: {
              borderWidth: 0,
              color: '#2697ff',
            },
          },
        ],
        xAxis: {
          //是否显示x轴
          margin: '-200',
          show: true,
          //类型：类目轴
          type: 'category',
          //坐标轴刻度设置
          axisTick: {
            //设置刻度线与标签对齐
            alignWithLabel: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              //轴线颜色
              // color: '#92adce',
              //线型
              type: 'solid',
            },
          },
          data: this.dimContent,
          splitLine: { show: false },
        },
        yAxis: {
          type: 'value',
          //是否显示y轴
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              //轴线颜色
              // color: '#92adce',
              //线型
              type: 'solid',
            },
          },
          splitLine: { show: false },
        },
        series: this.dimList,
      }
      this.option = option
      var series = option['series']
      var fun = function (params) {
        var datavalue = 0
        for (var i = 0; i < series.length; i++) {
          datavalue += series[i].data[params.dataIndex]
        }
        return datavalue
      }
      if (series[series.length - 1]) {
        series[series.length - 1]['label']['formatter'] = fun
        series[series.length - 1]['label']['show'] = true
      }
      myChart.setOption(option, true)
      //点击图例求和
      myChart.off('legendselectchanged')
      myChart.on('legendselectchanged', function (obj) {
        var series = option['series']
        var b = obj.selected,
          d = []
        for (var key in b) {
          if (b[key]) {
            for (var i = 0, l = series.length; i < l; i++) {
              var changename = series[i]['name']
              if (changename == key) {
                d.push(i)
              }
            }
          }
        }
        var fun = function (params) {
          var datavalue = 0
          for (var i = 0; i < d.length; i++) {
            for (var j = 0; j < series.length; j++) {
              if (d[i] == j) {
                datavalue += series[j].data[params.dataIndex]
              }
            }
          }
          return datavalue
        }
        for (var i = 0; i < series.length; i++) {
          series[i]['label']['show'] = false
        }
        for (var i = series.length - 1; i >= 0; i--) {
          var name = series[i]['name']
          if (obj['selected'][name]) {
            series[i]['label']['formatter'] = fun
            series[i]['label']['show'] = true
            break
          }
        }
        myChart.setOption(option)
      })
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.bt1 {
  text-align: center;
  padding-top: 10px;
}
::v-deep .el-card__header {
  padding: 10px 20px;
}
.bt {
  width: 70%;
  margin: auto;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  div {
    cursor: pointer;
    width: 32%;
    text-align: center;
    line-height: 25px;
    border-radius: 8px;
    padding: 20px 0px;
  }
  div span:nth-of-type(1) {
    font-size: 13px;
  }
  div span:nth-of-type(2) {
    font-size: 25px;
    font-weight: 600;
  }
  .btOne {
    background: #5cb1ff;
  }
  .btTwo {
    background: #71dada;
  }
  .btThree {
    background: #82da9c;
  }
}
</style>