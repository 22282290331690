import request from '@/utils/request'
let resquest = "/api/"

//获取业务部门-订单量分析指标数据
export function professionGetDempOrders(data) {
    return request({
        url: `${resquest}api/metrics/profession/getDempOrders`,
        method: 'post',
        data
    })
}

//获取业务部门-货量分析指标数据
export function professionGetDempTeus(data) {
    return request({
        url: `${resquest}api/metrics/profession/getDempTeus`,
        method: 'post',
        data
    })
}

//获取业务部门-部门下单量指标数据
export function professionGetDempClient(data) {
    return request({
        url: `${resquest}api/metrics/profession/getDempClient`,
        method: 'post',
        data
    })
}

//获取业务部门-部门下单量指标数据
export function professionGetDempOrdersRanking(data) {
    return request({
        url: `${resquest}api/metrics/profession/getDempOrdersRanking`,
        method: 'post',
        data
    })
}

//下拉框-有货量的目的港
export function professionGetDestPortList(data) {
    return request({
      url: `${resquest}api/base/profession/getDestPortList`,
      method: 'post',
      data
    })
  }

//获取业务部门-目的港柜量趋势指标数据
export function professionGetDestPortTeus(data) {
    return request({
        url: `${resquest}api/metrics/profession/getDestPortTeus`,
        method: 'post',
        data
    })
}

//下拉框-价格趋势-有货量的起运港
export function professionGetPricePolPortList(data) {
    return request({
        url: `${resquest}api/base/profession/getPricePolPortList`,
        method: 'post',
        data
    })
}

//下拉框-价格趋势-有货量的目的港
export function professionGetPriceDestPortListByPolPort(data) {
    return request({
        url: `${resquest}api/base/profession/getPriceDestPortListByPolPort`,
        method: 'post',
        data
    })
}

//获取业务起运港目的港价格趋势
export function professionGetPolDestPortPrice(data) {
    return request({
        url: `${resquest}api/metrics/profession/getPolDestPortPrice`,
        method: 'post',
        data
    })
}

//下拉框-价格趋势-有货量的区域
export function professionGetPriceDestLaneList(data) {
    return request({
        url: `${resquest}api/base/profession/getPriceDestLaneList`,
        method: 'post',
        data
    })
}

//获取业务航线止（区域）价格趋势
export function professionGetDestLanePrice(data) {
    return request({
        url: `${resquest}api/metrics/profession/getDestLanePrice`,
        method: 'post',
        data
    })
}
