var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.iconList, function(item, index) {
      return _c(
        "el-col",
        { key: index, attrs: { lg: 3, md: 3, sm: 6, xl: 3, xs: 12 } },
        [
          item.click && item.click === "changeTheme"
            ? _c(
                "el-card",
                {
                  staticClass: "icon-panel",
                  attrs: { shadow: "hover" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.changeTheme.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("vab-icon", {
                    style: { color: item.color },
                    attrs: { icon: item.icon }
                  }),
                  _c("p", [_vm._v(_vm._s(item.title))])
                ],
                1
              )
            : item.click && item.click === "randomTheme"
            ? _c(
                "el-card",
                {
                  staticClass: "icon-panel",
                  attrs: { shadow: "hover" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.randomTheme.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("vab-icon", {
                    style: { color: item.color },
                    attrs: { icon: item.icon }
                  }),
                  _c("p", [_vm._v(_vm._s(item.title))])
                ],
                1
              )
            : item.click && item.click === "handleMore"
            ? _c(
                "el-card",
                {
                  staticClass: "icon-panel",
                  attrs: { shadow: "hover" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.handleMore.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("vab-icon", {
                    style: { color: item.color },
                    attrs: { icon: item.icon }
                  }),
                  _c("p", [_vm._v(_vm._s(item.title))])
                ],
                1
              )
            : _c(
                "vab-link",
                { attrs: { target: "_blank", to: item.link } },
                [
                  _c(
                    "el-card",
                    { staticClass: "icon-panel", attrs: { shadow: "hover" } },
                    [
                      _c("vab-icon", {
                        style: { color: item.color },
                        attrs: { icon: item.icon }
                      }),
                      _c("p", [_vm._v(_vm._s(item.title))])
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }