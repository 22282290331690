<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <el-card shadow="hover">
      <template #header>
        <div class="bt1">
          <vab-icon icon="bar-chart-2-line" />
          部门票数趋势
        </div>
      </template>
      <div style="width: 100%; height: 500px" ref="myChart" id="CeShi"></div>
    </el-card>
  </el-col>
</template>
<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
export default {
  name: 'CeShi',
  data() {
    return {
      option: {},
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'settings/collapse',
    }),
  },
  watch: {
    collapse(vlaue) {
      setTimeout(() => {
        var myChart = echarts.init(document.getElementById('CeShi'))
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.option)
        myChart.resize()
      }, 500)
    },
  },
  mounted() {
    this.optionList()
  },
  methods: {
    optionList() {
      var chartDom = document.getElementById('CeShi')
      var myChart = echarts.init(chartDom)
      var option = {
        color: ['#1890FF', '#FFA500', '#FF69B4', '#48D1CC', '#9370DB'],
        tooltip: {
          trigger: 'axis',
          order: 'valueDesc',
          extraCssText: 'z-index:1',
          enterable: true, //悬停
          extraCssText: 'max-width:60%;max-height:60%; overflow: auto; ', //滚动条
          //改变提示框的位置 不超出屏幕显示
          position: function (point, params, dom, rect, size) {
            //其中point为当前鼠标的位置，
            //size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0 // x坐标位置
            var y = 0 // y坐标位置
            // 当前鼠标位置
            var pointX = point[0]
            var pointY = point[1]
            // 提示框大小
            var boxWidth = size.contentSize[0]
            var boxHeight = size.contentSize[1]
            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5
            } else {
              // 左边放的下
              x = pointX - boxWidth
            }
            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5
            } else {
              // 上边放得下
              y = pointY - boxHeight
            }
            return [x, y]
          },
          // alwaysShowContent: true,
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let newParams = []
            let tooltipString = []
            let shu1 = []
            let shu2 = []
            newParams = [...params]
            newParams.sort((a, b) => {
              return b.value - a.value
            })
            let sum1 = 0
            let sum2 = 0
            shu1.push('<div>' + '货量' + '</div>')
            shu2.push('<div>' + '票数' + '</div>')
            newParams.forEach((p) => {
              if (option.series[p.seriesIndex].stack == '总和') {
                sum1 += p.value
                // if (p.value != 0) {
                const cont1 =
                  p.marker + ' ' + p.seriesName + ': ' + p.value + '<br/>'
                shu1.push(cont1)
                // }
              } else {
                sum2 += p.value
                // if (p.value != 0) {
                const cont2 =
                  p.marker + ' ' + p.seriesName + ': ' + p.value + '<br/>'
                shu2.push(cont2)
              }
            })
            shu1.push(`合计 : ${sum1}`)
            shu2.push(`合计 : ${sum2}`)
            tooltipString = [...shu1, ...shu2]
            // console.log(tooltipString);
            return tooltipString.join('')
          },
        },
        legend: {
          // data: ['票数','货量'],
        },
        dataZoom: [
          {
            type: 'slider', //有单独的滑动条，用户在滑动条上进行缩放或漫游。inside是直接可以是在内部拖动显示
            show: true, //是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
            start: this.start, //数据窗口范围的起始百分比0-100
            end: this.end, //数据窗口范围的结束百分比0-100
            xAxisIndex: [0], // 此处表示控制第一个xAxis，设置 dataZoom-slider 组件控制的 x轴 可是已数组[0,2]表示控制第一，三个；xAxisIndex: 2 ，表示控制第二个。yAxisIndex属性同理
            bottom: '0px', //距离底部的距离
            height: 12,
            handleSize: '80%',
            handleIcon:
              'path://M512,512m-448,0a448,448,0,1,0,896,0a448,448,0,1,0,-896,0Z',
            handleStyle: {
              borderWidth: 0,
              color: '#2697ff',
            },
          },
        ],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: {
          //是否显示x轴
          margin: '-200',
          show: true,
          //类型：类目轴
          type: 'category',
          //坐标轴刻度设置
          axisTick: {
            //设置刻度线与标签对齐
            alignWithLabel: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              //轴线颜色
              // color: '#92adce',
              //线型
              type: 'solid',
            },
          },
          data: ['业务一部', '业务二部', '业务三部', '业务四部', '业务五部'],
          splitLine: { show: false },
        },
        yAxis: {
          type: 'value',
          //是否显示y轴
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              //轴线颜色
              // color: '#92adce',
              //线型
              type: 'solid',
            },
          },
          splitLine: { show: false },
        },
        series: [
          {
            name: '业务一部',
            type: 'bar',
            barWidth: '20%',
            stack: '总和',
            showSymbol: false,
            data: [60, 120, 45, 88, 96, 56],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务二部',
            type: 'bar',
            stack: '总和',
            barWidth: '20%',
            data: [120, 200, 150, 80, 70, 110],
            // z:'-1',
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务三部',
            type: 'bar',
            stack: '总和',
            barWidth: '20%',
            data: [2, 37, 66, 45, 77, 22],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务四部',
            type: 'bar',
            stack: '总和',
            barWidth: '20%',
            data: [33, 110, 59, 56, 44, 11],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务五部',
            type: 'bar',
            stack: '总和',
            barWidth: '20%',
            data: [41, 25, 77, 14, 26, 74],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务一部',
            type: 'bar',
            barWidth: '20%',
            stack: '总和2',
            showSymbol: false,
            data: [60, 120, 45, 88, 96, 56],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务二部',
            type: 'bar',
            stack: '总和2',
            barWidth: '20%',
            data: [110, 210, 130, 80, 70, 110],
            // z:'-1',
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务三部',
            type: 'bar',
            stack: '总和2',
            barWidth: '20%',
            data: [2, 37, 66, 45, 77, 22],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务四部',
            type: 'bar',
            stack: '总和2',
            barWidth: '20%',
            data: [33, 110, 59, 56, 44, 11],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
          {
            name: '业务五部',
            type: 'bar',
            stack: '总和2',
            barWidth: '20%',
            data: [41, 25, 77, 14, 26, 74],
            label: {
              show: false,
              position: 'top',
              color: 'black',
              formatter: function (params) {
                return params.value
              },
            },
          },
        ],
      }
      this.option = option
      var series = option['series']
      var fun = function (params) {
        var datavalue = 0
        for (var i = 0; i < series.length; i++) {
          if (series[i].stack == '总和') {
            datavalue += series[i].data[params.dataIndex]
          }
        }
        // return `货量：`+datavalue
        return datavalue
      }
      var fun1 = function (params) {
        var date1 = 0
        for (var i = 0; i < series.length; i++) {
          if (series[i].stack == '总和2') {
            date1 += series[i].data[params.dataIndex]
          }
        }
        // return `票数：`+date1
        return date1
      }
      var shuzu1 = 0
      for (var i = 0; i < series.length; i++) {
        if (series[i].stack == '总和') {
          shuzu1 += 1
        } 
      }
      if (series[shuzu1 - 1]) {
        series[shuzu1 - 1]['label']['formatter'] = fun
        series[shuzu1 - 1]['label']['show'] = true
      }
      if (series[series.length - 1]) {
        series[series.length - 1]['label']['formatter'] = fun1
        series[series.length - 1]['label']['show'] = true
      }
      myChart.setOption(option, true)
      //点击图例求和
      myChart.off('legendselectchanged')
      myChart.on('legendselectchanged', function (obj) {
        var series = option['series']
        var b = obj.selected,
          d = []
        for (var key in b) {
          if (b[key]) {
            for (var i = 0, l = series.length; i < l; i++) {
              var changename = series[i]['name']
              if (changename == key) {
                d.push(i)
              }
            }
          }
        }
        var fun1 = function (params) {
          var datavalue = 0
          for (var i = 0; i < d.length; i++) {
            for (var j = 0; j < series.length; j++) {
              if (d[i] == j && series[j].stack == '总和') {
                datavalue += series[j].data[params.dataIndex]
              }
            }
          }
          return datavalue
        }
        var fun2 = function (params) {
          var datavalue = 0
          for (var i = 0; i < d.length; i++) {
            for (var j = 0; j < series.length; j++) {
              if (d[i] == j && series[j].stack == '总和2') {
                datavalue += series[j].data[params.dataIndex]
              }
            }
          }
          return datavalue
        }
        for (var i = 0; i < series.length; i++) {
          series[i]['label']['show'] = false
        }
        for (var i = series.length - 1; i >= 0; i--) {
          var name = series[i]['name']
          if (obj['selected'][name]) {
            if (series[i].stack == '总和') {
            series[i]['label']['formatter'] = fun1
            series[i]['label']['show'] = true
            break
            }
          }
        }
        for (var i = series.length - 1; i >= 0; i--) {
          var name = series[i]['name']
          if (obj['selected'][name]) {
            if (series[i].stack == '总和2') {
            series[i]['label']['formatter'] = fun2
            series[i]['label']['show'] = true
            break
            }
          }
        }
        myChart.setOption(option)
      })
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.bt1 {
  text-align: center;
  padding-bottom: 10px;
}
</style>