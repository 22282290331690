var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "carousel-map" }, [
      _c("div", { staticClass: "bt" }, [
        _c("img", {
          attrs: { width: "150px", src: require("@/assets/logo.png"), alt: "" }
        })
      ]),
      _c("img", {
        staticClass: "breath",
        attrs: {
          src:
            "http://www.myeverocean.com/wp-content/uploads/2022/04/1d7d223ffedcdda26deecb9b750f448-scaled.jpg",
          width: "100%",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }