import request from '@/utils/request'
let resquest = "/api/"

//系统版本号查询
export function baseVersionList(data) {
  return request({
    url: `${resquest}base/version/list`,
    method: 'post',
    data
  })
}

//新增版本号
export function baseVersionAdd(data) {
  return request({
    url: `${resquest}base/version/add`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

//修改版本号
export function baseVersionUpdate(data) {
  return request({
    url: `${resquest}base/version/update`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

//删除版本号
export function baseVersionDelete(params) {
  return request({
    url: `${resquest}base/version/delete`,
    method: 'get',
    params
  })
}