<template>
  <div id="eachone" :style="{ height: '500px', left: '-44px' }"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'eCharts',
  data() {
    return {}
  },
  mounted() {
    this.drawEcharts()
  },
  methods: {
    drawEcharts() {
      var chartDom = document.getElementById('eachone')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: [
            '新增注册人数',
            '累计注册人数',
            '登录次数',
            '登录人数',
            '上行消息数',
            '发消息人数',
            'APNS 推送数',
            '活跃用户数',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [70, 11987, 1203, 159, 52, 207, 0, 159],
            itemStyle: { normal: { label: { show: true } } },
            type: 'line',
            areaStyle: {},
          },
        ],
      }

      option && myChart.setOption(option)
    },
  },
}
</script>