var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("page-header"),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [_c("access")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [_c("authorization")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
            [_c("version-information")],
            1
          ),
          _c("icon-list"),
          _c(
            "el-col",
            { attrs: { lg: 12, md: 12, sm: 24, xl: 12, xs: 24 } },
            [_c("china-map")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 12, md: 12, sm: 24, xl: 12, xs: 24 } },
            [_c("plan")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }