import request from '@/utils/request'
let resquest = "/api/"

//获取年度下拉框数据
export function getBaseYear(params) {
  return request({
    url: `${resquest}api/base/getYear`,
    method: 'get',
    params
  })
}

//根据年度获取月份下拉框数据
export function getBaseMonthByYear(params) {
  return request({
    url: `${resquest}api/base/getMonthByYear`,
    method: 'get',
    params
  })
}

//根据年度获取周别下拉框数据
export function getWeekByYear(params) {
  return request({
    url: `${resquest}api/base/getWeekByYear`,
    method: 'get',
    params
  })
}

//获取船司下拉框数据
export function getShippingCompany(data) {
  return request({
    url: `${resquest}api/base/getShippingCompany`,
    method: 'post',
    data
  })
}

//市场T量-票数表
export function getPageOrdersTeus(data) {
  return request({
    url: `${resquest}v2.0/api/report/business/getPageOrdersTeus`,
    method: 'post',
    data
  })
}

//部门T量-票数表
export function getPageDempOrdersTeus(data) {
  return request({
    url: `${resquest}v2.0/api/report/business/getPageDempOrdersTeus`,
    method: 'post',
    data
  })
}

//船司T量-票数表
export function getPageShipOrdersTeus(data) {
  return request({
    url: `${resquest}v2.0/api/report/business/getPageShipOrdersTeus`,
    method: 'post',
    data
  })
}

//订舱代理-船司T量表
export function getPageAgentOrdersTeus(data) {
  return request({
    url: `${resquest}v2.0/api/report/business/getPageAgentOrdersTeus`,
    method: 'post',
    data
  })
}

//订舱代理-航线T量表
export function getPageAgentDempTeus(data) {
  return request({
    url: `${resquest}v2.0/api/report/business/getPageAgentDempTeus`,
    method: 'post',
    data,
  })
}

//查询-财务明细-海运费
export function getPageShippingFeeDetails(data) {
  return request({
    url: `${resquest}api/report/finance/getPageShippingFeeDetails`,
    method: 'post',
    data,
  })
}

//查询-财务明细-应收应付-应付代理
export function getPagePayFeeDetails(data) {
  return request({
    url: `${resquest}api/report/finance/getPagePayFeeDetails`,
    method: 'post',
    data,
  })
}

//查询-财务结算中心应收未销账数据
export function getPageAscRecUnpaidList(data) {
  return request({
    url: `${resquest}api/report/finance/getPageAscRecUnpaidList`,
    method: 'post',
    data,
  })
}


//应收动态表-结算对象下拉框
export function getAscRecSettlementObjectList(data) {
  return request({
    url: `${resquest}api/finance/getAscRecSettlementObjectList`,
    method: 'post',
    data,
  })
}

//应收动态表-业务员下拉框
export function getAscRecSalesmanList(data) {
  return request({
    url: `${resquest}api/finance/getAscRecSalesmanList`,
    method: 'post',
    data,
  })
}


//查询-财务结算中心销账明细报表数据
export function getPageAscWriteOffDetails(data) {
  return request({
    url: `${resquest}api/report/finance/getPageAscWriteOffDetails`,
    method: 'post',
    data,
  })
}

//获取指定时间范围内财务费用代码
export function geExpenseCodeHistoryList(data) {
  return request({
    url: `${resquest}api/base/geExpenseCodeHistoryList`,
    method: 'post',
    data,
  })
}

//销账明细表-部门下拉框
export function getAscWriteOffDempList(data) {
  return request({
    url: `${resquest}api/finance/getAscWriteOffDempList`,
    method: 'post',
    data,
  })
}

//销账明细表-业务员下拉框
export function getAscWriteOffSalesmanList(data) {
  return request({
    url: `${resquest}api/finance/getAscWriteOffSalesmanList`,
    method: 'post',
    data,
  })
}

//销账明细表-结算对象下拉框
export function getAscWriteOffSettlementObjectList(data) {
  return request({
    url: `${resquest}api/finance/getAscWriteOffSettlementObjectList`,
    method: 'post',
    data,
  })
}

//获取订舱代理下拉框
export function baseFinanceGetAgentList(data) {
  return request({
    url: `${resquest}api/base/finance/getAgentList`,
    method: 'post',
    data,
  })
}
//分页获取订舱代理票数货量表
export function reportFinanceGetPageAgentOrdersTeus(data) {
  return request({
    url: `${resquest}api/report/finance/getPageAgentOrdersTeus`,
    method: 'post',
    data,
  })
}

//获取指定年月/周内商务部门有货量的订舱代理
export function getBusinessAgentHistoryByDateDimList(data) {
  return request({
    url: `${resquest}api/base/getBusinessAgentHistoryByDateDimList`,
    method: 'post',
    data,
  })
}

//获取指定年月/周内商务部门有货量的船司
export function getBusinessShippingHistoryByDateDimList(data) {
  return request({
    url: `${resquest}api/base/getBusinessShippingHistoryByDateDimList`,
    method: 'post',
    data,
  })
}

//获取指定年月/年周内的公司部门信息下拉框
export function getDempHistorytInfoListByYearsOrWeek(data) {
  return request({
    url: `${resquest}api/base/getDempHistorytInfoListByYearsOrWeek`,
    method: 'post',
    data,
  })
}

//获取品名分析表-订舱代理下拉框
export function getInfoAgentList(data) {
  return request({
    url: `${resquest}api/base/bussiness/getInfoAgentList`,
    method: 'post',
    data,
  })
}

//获取品名分析表-目的港下拉框
export function getInfoDestPortList(data) {
  return request({
    url: `${resquest}api/base/bussiness/getInfoDestPortList`,
    method: 'post',
    data,
  })
}

//分页获取商务报表-品名分析表
export function getPageProductName(data) {
  return request({
    url: `${resquest}v2.0/api/report/business/getPageProductName`,
    method: 'post',
    data,
  })
}