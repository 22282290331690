import request from '@/utils/request'
let resquest = "/api/"

//商务中心看板
//货运代理下拉框
export function getBookingAgent(data) {
  return request({
    url: `${resquest}api/base/getBookingAgent`,
    method: 'post',
    data
  })
}

//获取指定时间范围内商务部门的船司
export function getBusinessShippingHistoryList(data) {
  return request({
    url: `${resquest}api/base/getBusinessShippingHistoryList`,
    method: 'post',
    data
  })
}

//获取指定时间范围内商务部门的起运港
export function getBusinessPolPortHistoryList(data) {
  return request({
    url: `${resquest}api/base/getBusinessPolPortHistoryList`,
    method: 'post',
    data
  })
}

//部门票数-货量指标(遗弃)
export function getDempOrdersTeus(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getDempOrdersTeus`,
    method: 'post',
    data
  })
}

//代理船司-货量指标
export function getAngetShippingTeus(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getAngetShippingTeus`,
    method: 'post',
    data
  })
}

//代理-货量排名指标(废弃)
export function getAngetShippingTeusRanking(data) {
  return request({
    url: `${resquest}api/metrics/business/getAngetShippingTeusRanking`,
    method: 'post',
    data
  })
}

//代理-货量排名指标
export function getAngetDempTeusRanking(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getAngetDempTeusRanking`,
    method: 'post',
    data
  })
}


//人员-订舱代理-票数指标
export function getUserAngetShippingOrders(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getUserAngetShippingOrders`,
    method: 'post',
    data
  })
}

//人员-订舱代理-货量指标
export function getUserAngetShippingTeus(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getUserAngetShippingTeus`,
    method: 'post',
    data
  })
}

//获取指定时间范围内的公司部门信息下拉框
export function getDempHistorytInfoList(data) {
  return request({
    url: `${resquest}api/base/getDempHistorytInfoList`,
    method: 'post',
    data
  })
}

//获取指定时间范围内商务部门有货量的订舱代理
export function getBusinessAgentHistoryList(data) {
  return request({
    url: `${resquest}api/base/getBusinessAgentHistoryList`,
    method: 'post',
    data
  })
}

//获取商务部门应收tommy指标
export function getDempPayTommy(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getDempPayTommy`,
    method: 'post',
    data
  })
}

//获取商务部门应收tommy指标
export function getUserPayTommy(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getUserPayTommy`,
    method: 'post',
    data
  })
}

//空运商务看板
//部门票数-计费重量指标
export function getAirDempOrdersWeights(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getAirDempOrdersWeights`,
    method: 'post',
    data
  })
}

//代理船司-计费重量指标
export function getAirAngetShippingWeights(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getAirAngetShippingWeights`,
    method: 'post',
    data
  })
}

//代理-部门计费重量排名指标
export function getAirAngetShippingDempWeightsRanking(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getAirAngetShippingDempWeightsRanking`,
    method: 'post',
    data
  })
}

//人员-订舱代理-计费重量指标
export function getAirUserAngetShippingWeights(data) {
  return request({
    url: `${resquest}v2.0/api/metrics/business/getAirUserAngetShippingWeights`,
    method: 'post',
    data
  })
}