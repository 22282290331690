import request from '@/utils/request'
let resquest = "/api/"

//回话列表
export function chatList(params) {
  return request({
    url: `${resquest}msg/chat/list`,
    method: 'get',
    params
  })
}
//聊天记录
export function msgList(params) {
  return request({
    url: `${resquest}msg/list`,
    method: 'get',
    params
  })
}
