var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-management-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 4, md: 8, sm: 24, xl: 4, xs: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c("el-tree", {
                    attrs: {
                      data: _vm.data,
                      "default-expanded-keys": ["root"],
                      "node-key": "id",
                      props: _vm.defaultProps
                    },
                    on: { "node-click": _vm.handleNodeClick }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 20, md: 16, sm: 24, xl: 20, xs: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c(
                    "vab-query-form",
                    [
                      _c(
                        "vab-query-form-top-panel",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-plus", type: "primary" },
                              on: { click: _vm.handleEdit }
                            },
                            [_vm._v(" 添加 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      attrs: {
                        border: "",
                        data: _vm.list,
                        "default-expand-all": "",
                        "row-key": "path",
                        "tree-props": {
                          children: "children",
                          hasChildren: "hasChildren"
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "empty",
                          fn: function() {
                            return [
                              _c("el-image", {
                                staticClass: "vab-data-empty",
                                attrs: {
                                  src: require("@/assets/empty_images/data_empty.png")
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "标题",
                          prop: "meta.title",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "name",
                          prop: "name",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "路径",
                          prop: "path",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否隐藏",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.meta.hidden ? "是" : "否") +
                                      " "
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否隐藏当前节点",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.meta.levelHidden ? "是" : "否"
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "vue文件路径",
                          prop: "component",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "重定向",
                          prop: "redirect",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "图标", "show-overflow-tooltip": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.meta
                                  ? _c(
                                      "span",
                                      [
                                        row.meta.icon
                                          ? _c("vab-icon", {
                                              attrs: { icon: row.meta.icon }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否固定",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.meta
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.meta.noClosable ? "是" : "否"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否无缓存",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.meta
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.meta.noKeepAlive ? "是" : "否"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "badge", "show-overflow-tooltip": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.meta
                                  ? _c("span", [
                                      _vm._v(" " + _vm._s(row.meta.badge) + " ")
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "show-overflow-tooltip": "",
                          width: "85"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEdit(row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDelete(row)
                                      }
                                    }
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }