import { render, staticRenderFns } from "./ChinaMap.vue?vue&type=template&id=71680cd2&"
import script from "./ChinaMap.vue?vue&type=script&lang=js&"
export * from "./ChinaMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\kaopu_pms_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71680cd2')) {
      api.createRecord('71680cd2', component.options)
    } else {
      api.reload('71680cd2', component.options)
    }
    module.hot.accept("./ChinaMap.vue?vue&type=template&id=71680cd2&", function () {
      api.rerender('71680cd2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/index/components/ChinaMap.vue"
export default component.exports