import request from '@/utils/request'
let resquest = "/api/"

/**
 * 后台账号管理
 */

//管理员列表
export function adminList(params) {
  return request({
    url: `${resquest}admin/list`,
    method: 'get',
    params
  })
}
//删除管理员账号
export function adminDelete(params) {
  return request({
    url: `${resquest}admin/delete`,
    method: 'get',
    params
  })
}
//添加管理员账号
export function adminAdd(data) {
  return request({
    url: `${resquest}admin/add`,
    method: 'post',
    data
  })
}
//通过id查询管理员账号信息
export function adminGet(params) {
  return request({
    url: `${resquest}admin/get`,
    method: 'get',
    params
  })
}
//修改管理员账号
export function adminUpdate(data) {
  return request({
    url: `${resquest}admin/update`,
    method: 'post',
    data
  })
}

/**
 * 权限管理模块
 */

//角色权限列表
export function authList(params) {
  return request({
    url: `${resquest}auth/list`,
    method: 'get',
    params
  })
}
//通过id查询角色权限信息
export function authGet(params) {
  return request({
    url: `${resquest}auth/get`,
    method: 'get',
    params
  })
}
//获取菜单列表
export function modulList(params) {
  return request({
    url: `${resquest}module/list`,
    method: 'get',
    params
  })
}
//添加角色
export function authAdd(data) {
  return request({
    url: `${resquest}auth/add`,
    method: 'post',
    data
  })
}
//修改角色信息
export function authUpdate(data) {
  return request({
    url: `${resquest}auth/update`,
    method: 'post',
    data
  })
}
//删除角色
export function authDelete(params) {
  return request({
    url: `${resquest}auth/delete`,
    method: 'get',
    params
  })
}
//角色列表-无菜单权限
export function authListno(params) {
  return request({
    url: `${resquest}auth/list-no-auth`,
    method: 'get',
    params
  })
}

//初始化用户密码
export function adminInit(params) {
  return request({
    url: `${resquest}admin/init/password`,
    method: 'get',
    params
  })
}
