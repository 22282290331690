import request from '@/utils/request'
let resquest = "/api/"
//漂流瓶列表
export function bottleList(data) {
  return request({
    url: `${resquest}bottle/list`,
    method: 'post',
    data
  })
}

//删除瓶子
export function bottleDelete(params) {
  return request({
    url: `${resquest}bottle/delete`,
    method: 'get',
    params
  })
}

//修改浏览量
export function bottleUpdateVisit(params) {
  return request({
    url: `${resquest}bottle/update/visit`,
    method: 'post',
    params
  })
}

//隐藏漂流瓶
export function bottleHide(params) {
  return request({
    url: `${resquest}bottle/hide`,
    method: 'get',
    params
  })
}

//审核漂流瓶
export function bottleCheck(params) {
  return request({
    url: `${resquest}bottle/check`,
    method: 'post',
    params
  })
}

//分页查询漂流瓶评论-回复列表
export function commentList(params) {
  return request({
    url: `${resquest}comment/list`,
    method: 'get',
    params
  })
}

//删除评论
export function commentDelete(data) {
  return request({
    url: `${resquest}comment/delete`,
    method: 'post',
    data
  })
}

//隐藏-取消隐藏评论
export function commentHide(params) {
  return request({
    url: `${resquest}comment/hide`,
    method: 'get',
    params
  })
}

//B空间站列表
export function databSpaceList(data) {
  return request({
    url: `${resquest}base/bSpace/list`,
    method: 'post',
    data
  })
}

//删除B空间站
export function basebSpaceDelete(params) {
  return request({
    url: `${resquest}base/bSpace/delete`,
    method: 'get',
    params
  })
}

//新增B空间站
export function basebSpaceAdd(data) {
  return request({
    url: `${resquest}base/bSpace/add`,
    method: 'post',
    data
  })
}

//修改B空间站
export function basebSpaceUpdate(data) {
  return request({
    url: `${resquest}base/bSpace/update`,
    method: 'post',
    data
  })
}

//获取虚拟账户列表
export function basebVirtualUserList(params) {
  return request({
    url: `${resquest}bottle/virtual/user/list`,
    method: 'get',
    params
  })
}

//评论瓶子
export function bottleComment(params) {
  return request({
    url: `${resquest}bottle/comment`,
    method: 'post',
    params
  })
}

//分页查询漂评论回复列表
export function commentReplyList(params) {
  return request({
    url: `${resquest}comment/reply/list`,
    method: 'get',
    params
  })
}

//隐藏-取消隐藏回复
export function commentReplyHide(params) {
  return request({
    url: `${resquest}comment/reply/hide`,
    method: 'get',
    params
  })
}