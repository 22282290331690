<template>
  <div class="comprehensive-table-container">
    <div class="bt">
      <div>
        <h3>应收动态表</h3>
      </div>
      <div>
        <el-form ref="queryForm" :inline="true">
          <el-form-item label="业务编号：" prop="businessNo">
            <el-input
              style="width: 140px"
              clearable
              v-model="queryForm.businessNo"
              placeholder="请输入业务编号"
            />
          </el-form-item>
          <el-form-item label="部门：" prop="deptNameList">
            <el-select
              multiple
              filterable
              collapse-tags
              v-model="queryForm.deptNameList"
              @change="selectClickOne"
              clearable
              placeholder="请选择"
              style="width: 190px"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销账状态：" prop="writeOffType">
            <el-select
              filterable
              v-model="queryForm.writeOffType"
              @change="selectClickOne"
              clearable
              placeholder="请选择"
              style="width: 130px"
            >
              <el-option
                v-for="item in optionsTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员：" prop="salesman">
            <el-select
              filterable
              v-model="queryForm.salesman"
              @change="selectClickOne"
              clearable
              placeholder="请选择"
              style="width: 130px"
            >
              <el-option
                v-for="(item, index) in optionsFour"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结算对象：" prop="settlementObject">
            <el-select
              filterable
              v-model="queryForm.settlementObject"
              @change="selectClickOne"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in optionsThree"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <div style="float: right">
            <!-- <span style="padding-right: 20px">金额单位：USD</span> -->
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="success" @click="exPort" :disabled="list == null">
              导出
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      element-loading-text="拼命加载中"
      :height="height"
      @selection-change="setSelectRows"
      :stripe="true"
    >
      <el-table-column
        align="center"
        prop="pstarSys"
        label="业务主体"
        show-overflow-tooltip
        fixed="left"
      />
      <el-table-column
        align="center"
        prop="businessNo"
        label="业务编号"
        show-overflow-tooltip
        width="100px"
        fixed="left"
      />
      <el-table-column
        align="center"
        prop="salesman"
        label="业务员"
        show-overflow-tooltip
        width="100px"
        fixed="left"
      />
      <el-table-column
        align="center"
        prop="salesmanDempName"
        label="业务员部门"
        show-overflow-tooltip
        width="150px"
        fixed="left"
      />
      <el-table-column
        align="center"
        prop="operator"
        label="客服助理"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        prop="operatorDempName"
        label="客服部门"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        prop="documents"
        label="单证"
        show-overflow-tooltip
        width="110px"
      />
      <el-table-column
        align="center"
        label="揽货代理"
        prop="cargoAgent"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="结算对象"
        prop="settlementObject"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="预计开航日"
        prop="sailingDay"
        width="90px"
        show-overflow-tooltip
      />
      <!-- <el-table-column
        align="center"
        label="箱型"
        prop="box"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="箱型数量"
        prop="boxNum"
        show-overflow-tooltip
      /> -->
      <el-table-column
        align="center"
        label="实际开航日"
        prop="actualSailingDate"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="expenseCode"
        label="费用代码"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="amountOfMoney"
        label="金额"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="paidAmount"
        label="已收付金额"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="unpaidAmount"
        label="未收款金额	"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="curType"
        label="币别	"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="invoiceDate"
        label="开票日期"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="发票号"
        prop="invoiceNo"
        show-overflow-tooltip
        width="100px"
      ></el-table-column>
      <el-table-column
        align="center"
        label="发票抬头"
        prop="invoiceTitle"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        align="center"
        label="箱号"
        prop="containerNo"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="主提单号"
        prop="masterLadingNo"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="账期"
        prop="accountPeriod"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="结算方式"
        prop="settlementMethod"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="到期日"
        prop="expiryDay"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="欠款天数"
        prop="arrearsDays"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column
        align="center"
        label="超期天数"
        prop="overdueDays"
        show-overflow-tooltip
        fixed="right"
      />
      <el-table-column
        align="center"
        label="账龄天数"
        prop="agingDays"
        show-overflow-tooltip
        fixed="right"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import { getDemp } from '@/api/metrics'
import {
  getPageAscRecUnpaidList,
  getAscRecSettlementObjectList,
  getAscRecSalesmanList,
} from '@/api/businessStatement'
import axios from 'axios'
export default {
  props: {
    dataTime: {
      type: Object,
    },
    psSysList: Array,
    // statisticType: {
    //   type: String,
    // },
  },
  data() {
    return {
      dempTypeId: '1',
      height: '470px',
      listLoading: false,
      list: [],
      selectRows: '',
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        businessNo: '',
        deptNameList: [],
        writeOffType: '',
        settlementObject: '',
        salesman: '',
      },
      options: [],
      optionsThree: [],
      optionsFour: [],
      optionsTwo: [
        {
          id: '1',
          name: '已销账',
        },
        {
          id: '2',
          name: '部分销账',
        },
        {
          id: '3',
          name: '未销账',
        },
      ],
      total: 0,
      layout: 'total, sizes, prev, pager, next, jumper',
    }
  },
  watch: {
    // form: {
    //   handler(newValue, oldValue) {
    //     this.queryForm.pageNum = 1
    //   },
    //   deep: true,
    // },
    // statisticType: {
    //   handler(newValue, oldValue) {
    //     this.selectTwo()
    //     this.queryList()
    //   },
    // },
    psSysList: {
      handler(newValue, oldValue) {
        this.queryForm.deptNameList = []
        this.selectTwo()
        this.queryList()
      },
      deep: true,
    },
    dataTime: {
      handler(newValue, oldValue) {
        this.queryForm.settlementObject = ''
        this.queryForm.salesman = ''
        this.selectTwo()
        this.queryList()
      },
      deep: true,
    },
  },
  mounted() {
    this.selectTwo()
  },
  methods: {
    async selectTwo() {
      // let data = {
      //   companyId: this.company.companyId,
      //   beginDate: this.dataTime.beginDate ? this.dataTime.beginDate : '',
      //   endDate: this.dataTime.endDate ? this.dataTime.endDate : '',
      //   // statisticType: this.statisticType,
      // }
      // const res = await geExpenseCodeHistoryList(data)
      // this.options = res.data

      let data = {
        companyId: this.company.companyId,
        dempTypeId: this.dempTypeId,
        psSysList: this.psSysList,
      }
      let res = await getDemp(data)
      this.options = res.data
      console.log(this.options)
      let dataTwo = {
        companyId: this.company.companyId,
        beginDate: this.dataTime.beginDate ? this.dataTime.beginDate : '',
        endDate: this.dataTime.endDate ? this.dataTime.endDate : '',
        psSysList: this.psSysList,
      }
      let req = await getAscRecSettlementObjectList(dataTwo)
      this.optionsThree = req.data
      let rew = await getAscRecSalesmanList(dataTwo)
      this.optionsFour = rew.data
    },
    selectClickOne() {
      this.queryList()
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.queryList()
    },
    exPort() {
      this.listLoading = true
      let data = {
        companyId: this.company.companyId,
        beginDate: this.dataTime.beginDate ? this.dataTime.beginDate : '',
        endDate: this.dataTime.endDate ? this.dataTime.endDate : '',
        businessNo: this.queryForm.businessNo,
        deptNameList: this.queryForm.deptNameList,
        writeOffType: this.queryForm.writeOffType,
        settlementObject: this.queryForm.settlementObject,
        salesman: this.queryForm.salesman,
        psSysList: this.psSysList,
      }
      axios({
        url: `/api/api/report/finance/exportAscRecUnpaidList`,
        data,
        method: 'post',
        responseType: 'blob',
        headers: {
          token: localStorage.getItem('token'),
        },
      })
        .then((response) => {
          const content = response.data
          const blob = new Blob([content])
          // const fileName = decodeURIComponent(
          //   response.headers['content-disposition'].split('=')[1] //文件名
          // )
          const fileName = response.headers['content-disposition'].split('=')[1] //文件名
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
          // eslint-disable-next-line handle-callback-err
          this.listLoading = false
        })
        .catch((error) => {
          this.listLoading = false
          console.log(error)
        })
    },
    handleQuery() {
      this.queryForm.pageNum = 1
      this.queryList()
    },
    async queryList() {
      this.listLoading = true
      let data = {
        pageNum: this.queryForm.pageNum,
        pageSize: this.queryForm.pageSize,
        companyId: this.company.companyId,
        beginDate: this.dataTime.beginDate ? this.dataTime.beginDate : '',
        endDate: this.dataTime.endDate ? this.dataTime.endDate : '',
        businessNo: this.queryForm.businessNo,
        deptNameList: this.queryForm.deptNameList,
        writeOffType: this.queryForm.writeOffType,
        settlementObject: this.queryForm.settlementObject,
        salesman: this.queryForm.salesman,
        psSysList: this.psSysList,
        // statisticType: this.statisticType,
      }
      const res = await getPageAscRecUnpaidList(data)
      this.list = res.data.pageList
      this.total = res.data.totalSize
      this.listLoading = false
    },
  },
}
</script>
<style lang="scss" scoped>
</style>