var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "version-information",
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("vab-icon", { attrs: { icon: "information-line" } }),
              _vm._v(" 信息 "),
              _c("el-tag", { staticClass: "card-header-tag" }, [
                _vm._v("部署时间:" + _vm._s(_vm.updateTime))
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("el-scrollbar", [
        _c("table", { staticClass: "table" }, [
          _c("tr", [
            _c("td", [_vm._v("vue")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["vue"]))]),
            _c("td", [_vm._v("@vue/cli")]),
            _c("td", [_vm._v(_vm._s(_vm.devDependencies["@vue/cli-service"]))])
          ]),
          _c("tr", [
            _c("td", [_vm._v("vuex")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["vuex"]))]),
            _c("td", [_vm._v("vue-router")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["vue-router"]))])
          ]),
          _c("tr", [
            _c("td", [_vm._v("element-ui")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["element-ui"]))]),
            _c("td", [_vm._v("axios")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["axios"]))])
          ]),
          _c("tr", [
            _c("td", [_vm._v("授权渠道")]),
            _c("td", { attrs: { colspan: "3" } })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }