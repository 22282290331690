import request from '@/utils/request'
let resquest = "/api/"

//获取日志列表
export function userlogList(data) {
  return request({
    url: `${resquest}userlog/list`,
    method: 'post',
    data
  })
}