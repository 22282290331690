const data = {
  description:
    '最近更新：1、重构动态路由格式。 2、全面升级多功能自定义表格。3、分栏布局支持小箭头风格样式。4、添加定时refresh token机制。618狂欢节，全年最低价，<a href="http://vue-admin-beautiful.com/authorization/" target="_blank">点我购买</a>',
}

module.exports = [
  {
    url: '/description/getList',
    type: 'get',
    response: () => {
      return {
        code: 200,
        msg: 'success',
        data,
      }
    },
  },
]
