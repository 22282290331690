<template>
  <div id="each" :style="{ width: '1182px', height: '500px' }"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  props: {
    shu: Object,
  },
  data() {
    return {}
  },
  mounted() {
    this.drawEcharts()
  },
  watch: {
    // 'this.shu': function (newVal) {
    //   if (newVal) {
    //     console.log(1)
    //   }
    //   // console.log(newVal)
    //   // this.drawEcharts()
    // },
    'shu.data1': {
      handler() {
        this.drawEcharts()
      },
      immediate: true,
    },
  },
  methods: {
    drawEcharts() {
      var chartDom = document.getElementById('each')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        // title: {
        //   text: 'Waterfall Chart',
        //   subtext: 'Living Expenses in Shenzhen',
        // },
        // tooltip: {
        //   trigger: 'axis',
        // axisPointer: {
        //   type: 'shadow',
        // },
        //   formatter: function (params) {
        //     var tar = params[1]
        //     return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value
        //   },
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: [
            '20220314',
            '20220317',
            '20220320',
            '20220323',
            '20220326',
            '20220329',
            '20220401',
            '20220404',
            '20220407',
            '20220410',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Life Cost',
            type: 'bar',
            label: {
              show: true,
              position: 'inside',
            },
            // data: [89, 96, 78, 79, 115, 92, 172, 184, 143, 179],
            data: this.shu.data1,
          },
          {
            name: 'Life Cost',
            type: 'bar',
            label: {
              show: true,
              position: 'inside',
            },
            // data: [84, 94, 93, 66, 136, 135, 187, 221, 958, 159],
            data: this.shu.data2,
          },
          {
            name: 'Life Cost',
            type: 'bar',
            label: {
              show: true,
              position: 'inside',
            },
            // data: [90, 139, 85, 84, 127, 202, 199, 234, 294, 155],
            data: this.shu.data3,
          },
        ],
      }

      option && myChart.setOption(option)
    },
  },
}
</script>