var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "bt1" },
                    [
                      _c("vab-icon", { attrs: { icon: "bar-chart-2-line" } }),
                      _vm._v(" 部门票数趋势 ")
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", {
            ref: "myChart",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { id: "CeShi" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }